import React, { useEffect, useMemo } from "react";
import HomeLayout from "../../designs/Dashboard/HomeLayout";
import { useState } from "react";
import { useQuery } from "react-query";
import { DashboardLayout } from "../../designs/Dashboard/DashboardLayout";
import { navigateAsPerSessionValidity, sort } from "../../services/helpers";
import useTests from "../../hooks/useTests";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../stores";
const Dashboard = () => {
	useEffect(() => {
		navigateAsPerSessionValidity(true);
	});
	const navigate = useNavigate();

	const { getPackageList, getSessions } = useTests();
	const [subscribedPackages, setSubscribedPackages] = useState();
	const student = JSON.parse(window.localStorage.getItem("current_student"));
	let school = window.sessionStorage.getItem("school");
	let curentUser = useStore((state) => state.currentUser);
	const [subject, setSubject] = useState();

	const { data: olympiads } = useQuery(
		[`LiveOlympiad`, student?.grade, subject],
		() =>
			getPackageList({
				grade: student?.grade,
				series: "LiveOlympiad",
				subject: subject
			}),
		{ enabled: !!student?.grade && !!curentUser?.id, retry: false }
	);

	const { data: sessions } = useQuery([`Sessions`, student?.studIndex], () =>
		getSessions(student?.studIndex)
	);

	useEffect(() => {
		if (!olympiads?.data || !student.subjects) return; // Early exit if data or subjects are undefined
	  
		let packageList = olympiads.data;
	  
		setSubscribedPackages(
			packageList.filter((test) =>
				student.subjects.some((subject) => test.subject[0] === subject)
			)
		);
	}, [olympiads]);

	const sessionStart = (sessionStartData) => {
		navigate(`/SessionStart/${curentUser?.access_token}/` + sessionStartData);
	};
	const clearCurrentUser = useStore((state) => state.clearCurrentUser);
	return (
		<HomeLayout logOutHandler={clearCurrentUser}>
			<DashboardLayout
				sessions={sessions?.data}
				olympiads={subscribedPackages}
				subject={subject}
				setSubject={setSubject}
			/>
		</HomeLayout>
	);
};

export default Dashboard;
