import Card1 from "../../components/card1";
import { Grid, Stack, Typography } from "@mui/material";

const Olympiads = ({
	olympiads,
}) => {
	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography p={2}></Typography>
			</Grid>
			<Stack direction="row" sx={{ overflowX: "auto" }}>
				{olympiads?.map((olympiad) => (
					<div key={olympiad?._id}>
					</div>
				))}
			</Stack>
		</Grid>
	);
};

export default Olympiads;
