import { Box, Grid } from "@mui/material";
import { CustomListItem } from "../../components/CustomListItem";
import { SubjectSelector } from "../../components/SubjectSelector";
import Olympiads from "./Olympiads";
import { useMemo } from "react";
// import { CustomListItem } from "../../components/CustomListItem"
let nationalOlympiad = {
	_id: "sample_id",
	packageName: "National Level Olympiad",
	startTime: new Date("2024-01-28").toISOString(),
};
export const DashboardLayout = ({
	olympiads,
	subject,
	setSubject,
	sessions,
}) => {
	const qualification = useMemo(() => {
		let qualification = {};
		sessions?.forEach((session) => {
			if (
				session?.certificate === "EXCELLENCE" ||
				session?.certificate === "MERIT"
			) {
				qualification[session?.subjects[0]] = true;
			}
		});
		return qualification;
	}, [sessions]);
	return (
		<Grid container mt={1}>
			<Grid item xs={12} sm={12} md={12} lg={8}>
				<Olympiads
					olympiads={olympiads}
					sessions={sessions}
					qualification={qualification}
				/>
				<SubjectSelector subject={subject} setSubject={setSubject} />
				{olympiads?.map((olympiad) => (
					<Box key={olympiad?._id} sx={{ marginTop: 2 }}>
						{olympiad?.subject[0] === subject && (
							<CustomListItem mockTest={olympiad} sessions={sessions} />
						)}
					</Box>
				))}
			</Grid>
		</Grid>
	);
};
